import Vue from 'vue'

export default {
  getOrdersReport(data) {
    return Vue.axios.post(
      '/dashboard/reports/orders/xlsx',
      data,
      { responseType: 'blob' }
    )
  },
  getParentsReport(data) {
    return Vue.axios.post(
      '/dashboard/reports/parents/xlsx',
      data,
      { responseType: 'blob' }
    )
  },
  getPaymentsReport(data) {
    return Vue.axios.post( '/dashboard/reports/payments/list/xlsx', data, {
      responseType: 'blob'
    })
  },
  getReportListPayments(data) {
    return Vue.axios.post('/dashboard/reports/payments/list', data)
  }
}
