<template>
  <div :class="$style.report">
    <Title text="Отчет по родителям" position="right" />

    <div :class="$style.report__header">
      <date-picker
        v-model="date"
        range
        :lang="lang"
        placeholder="Дата"
        :editable="false"
        :formatter="momentFormat"
      />

      <Button
        :class="$style.report__header__button"
        v-if="canDownload"
        type="tertiary"
        center
        :disabled="loading"
        @click="getReport"
      >
        Скачать xlsx файл
      </Button>
    </div>
  </div>
</template>

<script>
import Title from '@/basic/Title'
import Button from '@/basic/Button'
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import Moment from 'moment'
import ReportsApi from '@/api/reports'
import 'vue2-datepicker/index.css'

export default {
  name: 'ParentsReport',
  components: {
    Title,
    Button,
    DatePicker
  },
  data() {
    return {
      lang: ru,
      date: null,
      loading: false,

      momentFormat: {
        stringify: (date) => {
          return date ? Moment(date).format('DD-MM-YYYY') : ''
        }
      }
    }
  },
  computed: {
    startDate() {
      return this.date && this.date.length && Moment(this.date[0]).format('YYYY-MM-DD')
    },
    endDate() {
      return this.date && this.date.length && Moment(this.date[1]).format('YYYY-MM-DD')
    },
    canDownload() {
      return this.startDate && this.endDate
    }
  },
  mounted() {
    if (this.$route.query.page) this.$router.push('/parentsReport')
  },
  methods: {
    async getReport() {
      try {
        this.loading = true

        const resp = await ReportsApi.getParentsReport({
          filter: {
            createdAt: {
              start: this.startDate,
              end: this.endDate
            }
          }
        })

        if (resp?.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(resp.data)
          link.download = `Отчет по родителям с ${Moment(this.startDate).format(
                  'DD-MM-YYYY'
          )} по ${Moment(this.endDate).format('DD-MM-YYYY')}-${Date.now()}.xlsx`
          link.click()
        }
      } catch (error) {
        console.warn(error)
        this.openNotice('ErrorNotice', `Ошибка получения файла с сервера!`, 2500)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
  .pagination {
    display: flex;
    margin-top: 30px;
  }
  .page {
    cursor: pointer;
    background-color: #4444b7;
    margin-right: 10px;
    color: #fff;
    a {
      display: inline-block;
      padding: 10px;
    }
    &.active {
      background-color: #0909f3;
    }
  }
</style>

<style module lang="scss">
  @import '@/assets/styles/colors.scss';

  .report {
    &__header {
      display: flex;
      align-items: center;

      &__button {
        width: 200px;
        margin-left: 30px;
        padding: 5px 15px !important;
      }
    }

    &__counter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2rem 0 1rem;
    }

    &__table {
      width: 100%;
      border: 1px solid $table-border;

      &__header {
        &_row {
          display: flex;
          justify-content: space-between;
          height: 2.5rem;
          border-bottom: 1px solid $table-border;
        }

        &_column {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem;
          border-right: 1px solid $table-border;
          font-size: 1.125rem;
          font-weight: 500;

          &_icon {
            cursor: pointer;
            padding: 10px;
            transition: all 0.3s ease;

            &_active {
              transform: rotate(180deg);
            }
          }

          &:first-child {
            width: 9.5%;
          }

          &:nth-child(n + 2) {
            width: 18.5%;
          }

          &:last-child {
            border: none;
          }
        }
      }

      &__content {
        position: relative;
        display: flex;
        flex-direction: column;

        &_row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 2.5rem;
          border-bottom: 1px solid $table-border;
        }

        &_column {
          display: flex;
          align-items: center;
          padding: 0.7rem 1rem;
          font-size: 0.875rem;
          font-weight: 500;

          &:first-child {
            width: 9.5%;
            justify-content: center;
          }

          &:nth-child(n + 2) {
            width: 18.5%;
          }

          &:last-child {
            border: none;
          }
        }

        &_rowLine {
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;
        }

        &_line {
          border-right: 1px solid $table-border;

          &:first-child {
            width: 9.5%;
          }

          &:nth-child(n + 2) {
            width: 18.5%;
          }

          &:last-child {
            border: none;
          }
        }
      }
    }
  }
</style>
